/**
 * This is generated from the provision script in contract repo.
 */
export const did = {
    '$ref:wallets/deployer': {
        id: '3dc9a59f-fcc1-85b1-e4ce-6d353f88bcc2',
        address: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    },
    '$ref:wallets/hedera_testnet_deployer': {
        id: '9658cab5-74cd-3f59-8204-1f7e7210d6e1',
        address: '0x49034E81815dF48CCBAF268AcC48a2FA78582F78',
    },
    '$ref:wallets/hedera_mainnet_deployer': {
        id: '9658cab5-74cd-3f59-8204-1f7e7210d6e2',
        address: '0x43e3CE9ac780eCdA0c796678Fb6Dc74aFeF5D679',
    },
    '$ref:deployments/ShareRing_Mainnet_DocumentVerifiableCredentialsToken': {
        deploymentId: 'b0235d33-afa5-44e2-a667-d0fdea8877f0',
        address: '0xf83ea5628dedcc245f7896807d6bb098b98fbcf9',
        name: 'ShareRing_Mainnet_DocumentVerifiableCredentialsToken',
        chainId: '295',
        abiId: '997570a1-f926-4d51-8bfb-6427b343aa11',
        abi: [
            {
                type: 'constructor',
                inputs: [
                    {
                        name: 'defaultAdmin',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'pauser',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'allowedCaller',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'ALLOWED_CALLER_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'PAUSER_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'didTokenIdToTokenId',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'documentHashToTokenIds',
                inputs: [
                    {
                        name: 'documentHash',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'tokenIds',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getDocument',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint8',
                        internalType: 'enum IShare.VerificationLevels',
                    },
                    {
                        name: '',
                        type: 'bytes32[]',
                        internalType: 'bytes32[]',
                    },
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getDocumentHashToTokenIdsLength',
                inputs: [
                    {
                        name: 'key',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getDocumentVerificationLevel',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint8',
                        internalType: 'enum IShare.VerificationLevels',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'mintAndSetAttributesOnVct',
                inputs: [
                    {
                        name: 'tokenInput',
                        type: 'tuple',
                        internalType:
                            'struct DocumentVerifiableCredentialsToken.TokenInput',
                        components: [
                            {
                                name: 'didTokenId',
                                type: 'string',
                                internalType: 'string',
                            },
                            {
                                name: 'owner',
                                type: 'address',
                                internalType: 'address',
                            },
                        ],
                    },
                    {
                        name: 'document',
                        type: 'tuple',
                        internalType:
                            'struct DocumentVerifiableCredentialsToken.Document',
                        components: [
                            {
                                name: 'verificationLevel',
                                type: 'uint8',
                                internalType: 'enum IShare.VerificationLevels',
                            },
                            {
                                name: 'attributes',
                                type: 'bytes32[]',
                                internalType: 'bytes32[]',
                            },
                            {
                                name: 'vctDidTokenId',
                                type: 'string',
                                internalType: 'string',
                            },
                            {
                                name: 'documentHash',
                                type: 'bytes32',
                                internalType: 'bytes32',
                            },
                        ],
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'pause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeMint',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setVctAddress',
                inputs: [
                    {
                        name: '_vctAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenIdToDidTokenId',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'unpause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'vct',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'contract IVCT',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'vctAddress',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'BatchMetadataUpdate',
                inputs: [
                    {
                        name: '_fromTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: '_toTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MetadataUpdate',
                inputs: [
                    {
                        name: '_tokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MintAndSetAttributesOnVct',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                    {
                        name: 'vctDidTokenId',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                    {
                        name: 'verificationLevel',
                        type: 'uint8',
                        indexed: false,
                        internalType: 'enum IShare.VerificationLevels',
                    },
                    {
                        name: 'documentHash',
                        type: 'bytes32',
                        indexed: false,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'DvctTokenAlreadyExistError',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
        ],
        created: false,
    },
    '$ref:deployments/ShareRing_Mainnet_VerifiableCredentialsToken': {
        deploymentId: 'df2f4e46-c768-4f74-97c8-68c09cc06563',
        address: '0x73b9a9d21f6d6d518c8e91c59fe0c500c28de6ed',
        name: 'ShareRing_Mainnet_VerifiableCredentialsToken',
        chainId: '295',
        abiId: '03b1e5bd-548b-456a-a493-0e7e8d79ac73',
        abi: [
            {
                type: 'constructor',
                inputs: [
                    {
                        name: 'defaultAdmin',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'pauser',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'allowedCaller',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'ALLOWED_CALLER_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'PAUSER_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'didTokenIdToTokenId',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'documentsMap',
                inputs: [
                    {
                        name: 'vctTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'dvctTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'documentIndex',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getAttributesData',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'attribute',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: 'verificationLevel',
                        type: 'uint8',
                        internalType: 'enum IShare.VerificationLevels',
                    },
                    {
                        name: 'refDocuments',
                        type: 'uint256[]',
                        internalType: 'uint256[]',
                    },
                    {
                        name: 'curRefDocument',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getMerkleRoot',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isDocumentsMapSet',
                inputs: [
                    {
                        name: 'vctTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'dvctTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'isSet',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'merkleRoots',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'root',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'pause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeMint',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setAttributesData',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'document',
                        type: 'tuple',
                        internalType:
                            'struct VerifiableCredentialsToken.Document',
                        components: [
                            {
                                name: 'dvctAddress',
                                type: 'address',
                                internalType: 'address',
                            },
                            {
                                name: 'dvctDidTokenId',
                                type: 'string',
                                internalType: 'string',
                            },
                        ],
                    },
                    {
                        name: 'attributes',
                        type: 'bytes32[]',
                        internalType: 'bytes32[]',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setMerkleRoot',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: '_merkleRoot',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenIdToDidTokenId',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'unpause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'verifyAttribute',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'attribute',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'merkleProof',
                        type: 'bytes32[]',
                        internalType: 'bytes32[]',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'BatchMetadataUpdate',
                inputs: [
                    {
                        name: '_fromTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: '_toTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MetadataUpdate',
                inputs: [
                    {
                        name: '_tokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'SetAttributesData',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        indexed: true,
                        internalType: 'string',
                    },
                    {
                        name: 'dvctAddress',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'dvctDidTokenId',
                        type: 'string',
                        indexed: true,
                        internalType: 'string',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'VctAlreadyExistError',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
            },
        ],
        created: false,
    },
    '$ref:deployments/ShareRing_Testnet_DocumentVerifiableCredentialsToken': {
        deploymentId: 'c9395b8a-ecfe-44ee-95e5-75294b5f169d',
        address: '0xf8d16d79e3666259404df2e26028fbcd2db78fd2',
        name: 'ShareRing_Testnet_DocumentVerifiableCredentialsToken',
        chainId: '296',
        abiId: 'f9514914-a169-41b6-8f7f-af1ec880fcb2',
        abi: [
            {
                type: 'constructor',
                inputs: [
                    {
                        name: 'defaultAdmin',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'pauser',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'allowedCaller',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'ALLOWED_CALLER_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'PAUSER_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'didTokenIdToTokenId',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'documentHashToTokenIds',
                inputs: [
                    {
                        name: 'documentHash',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'tokenIds',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getDocument',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint8',
                        internalType: 'enum IShare.VerificationLevels',
                    },
                    {
                        name: '',
                        type: 'bytes32[]',
                        internalType: 'bytes32[]',
                    },
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getDocumentHashToTokenIdsLength',
                inputs: [
                    {
                        name: 'key',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getDocumentVerificationLevel',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint8',
                        internalType: 'enum IShare.VerificationLevels',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'mintAndSetAttributesOnVct',
                inputs: [
                    {
                        name: 'tokenInput',
                        type: 'tuple',
                        internalType:
                            'struct DocumentVerifiableCredentialsToken.TokenInput',
                        components: [
                            {
                                name: 'didTokenId',
                                type: 'string',
                                internalType: 'string',
                            },
                            {
                                name: 'owner',
                                type: 'address',
                                internalType: 'address',
                            },
                        ],
                    },
                    {
                        name: 'document',
                        type: 'tuple',
                        internalType:
                            'struct DocumentVerifiableCredentialsToken.Document',
                        components: [
                            {
                                name: 'verificationLevel',
                                type: 'uint8',
                                internalType: 'enum IShare.VerificationLevels',
                            },
                            {
                                name: 'attributes',
                                type: 'bytes32[]',
                                internalType: 'bytes32[]',
                            },
                            {
                                name: 'vctDidTokenId',
                                type: 'string',
                                internalType: 'string',
                            },
                            {
                                name: 'documentHash',
                                type: 'bytes32',
                                internalType: 'bytes32',
                            },
                        ],
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'pause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeMint',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setVctAddress',
                inputs: [
                    {
                        name: '_vctAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenIdToDidTokenId',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'unpause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'vct',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'contract IVCT',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'vctAddress',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'BatchMetadataUpdate',
                inputs: [
                    {
                        name: '_fromTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: '_toTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MetadataUpdate',
                inputs: [
                    {
                        name: '_tokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MintAndSetAttributesOnVct',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                    {
                        name: 'vctDidTokenId',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                    {
                        name: 'verificationLevel',
                        type: 'uint8',
                        indexed: false,
                        internalType: 'enum IShare.VerificationLevels',
                    },
                    {
                        name: 'documentHash',
                        type: 'bytes32',
                        indexed: false,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'DvctTokenAlreadyExistError',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
        ],
        created: false,
    },
    '$ref:deployments/ShareRing_Testnet_VerifiableCredentialsToken': {
        deploymentId: 'd4b5a198-cb5b-4d44-bed9-12b00d2641b5',
        address: '0xc0177fe44f40b3fb7d69bf51e4918c47b7dbb873',
        name: 'ShareRing_Testnet_VerifiableCredentialsToken',
        chainId: '296',
        abiId: 'bba93ca8-7ac6-40df-b74b-eb18643ab549',
        abi: [
            {
                type: 'constructor',
                inputs: [
                    {
                        name: 'defaultAdmin',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'pauser',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'allowedCaller',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'ALLOWED_CALLER_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'PAUSER_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'didTokenIdToTokenId',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'documentsMap',
                inputs: [
                    {
                        name: 'vctTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'dvctTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'documentIndex',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getAttributesData',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'attribute',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: 'verificationLevel',
                        type: 'uint8',
                        internalType: 'enum IShare.VerificationLevels',
                    },
                    {
                        name: 'refDocuments',
                        type: 'uint256[]',
                        internalType: 'uint256[]',
                    },
                    {
                        name: 'curRefDocument',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getMerkleRoot',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isDocumentsMapSet',
                inputs: [
                    {
                        name: 'vctTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'dvctTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'isSet',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'merkleRoots',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'root',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'pause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeMint',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setAttributesData',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'document',
                        type: 'tuple',
                        internalType:
                            'struct VerifiableCredentialsToken.Document',
                        components: [
                            {
                                name: 'dvctAddress',
                                type: 'address',
                                internalType: 'address',
                            },
                            {
                                name: 'dvctDidTokenId',
                                type: 'string',
                                internalType: 'string',
                            },
                        ],
                    },
                    {
                        name: 'attributes',
                        type: 'bytes32[]',
                        internalType: 'bytes32[]',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setMerkleRoot',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: '_merkleRoot',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenIdToDidTokenId',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'unpause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'verifyAttribute',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'attribute',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'merkleProof',
                        type: 'bytes32[]',
                        internalType: 'bytes32[]',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'BatchMetadataUpdate',
                inputs: [
                    {
                        name: '_fromTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: '_toTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MetadataUpdate',
                inputs: [
                    {
                        name: '_tokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'SetAttributesData',
                inputs: [
                    {
                        name: 'didTokenId',
                        type: 'string',
                        indexed: true,
                        internalType: 'string',
                    },
                    {
                        name: 'dvctAddress',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'dvctDidTokenId',
                        type: 'string',
                        indexed: true,
                        internalType: 'string',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'VctAlreadyExistError',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
            },
        ],
        created: false,
    },
    '$ref:contracts/FSCOProofs_Mainnet_Declaration': {
        deploymentId: '3bf1ca64-fc3c-4265-8cdf-5e1fa611085e',
        address: '0xb80B3c7a68fb94D45AEd4758ED80c001888Eb8aA',
        name: 'FSCOProofs_Mainnet_Declaration',
        chainId: '295',
        abiId: 'e0bdde0f-84c3-4380-84e3-924fad52b051',
        abi: [
            {
                type: 'constructor',
                inputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'approveWallet',
                inputs: [
                    {
                        name: 'approvedAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'burn',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getLatestInfo',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getLatestVersion',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getVersionInfo',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForWallet',
                inputs: [
                    {
                        name: 'wallet',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'mint',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'owner',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'renounceOwnership',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeWallet',
                inputs: [
                    {
                        name: 'revokedAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setTokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: '_tokenURI',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: '_name',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenByIndex',
                inputs: [
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenOfOwnerByIndex',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'totalSupply',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'transferOwnership',
                inputs: [
                    {
                        name: 'newOwner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'versionExists',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'BatchMetadataUpdate',
                inputs: [
                    {
                        name: '_fromTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: '_toTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'DeclarationBurned',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'DeclarationMinted',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'uri',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'DeclarationURIUpdated',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: 'newUri',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                    {
                        name: 'newName',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MetadataUpdate',
                inputs: [
                    {
                        name: '_tokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'OwnershipTransferred',
                inputs: [
                    {
                        name: 'previousOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'newOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'WalletApproved',
                inputs: [
                    {
                        name: 'wallet',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approvedAddress',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'WalletRevoked',
                inputs: [
                    {
                        name: 'wallet',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'revokedAddress',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721EnumerableForbiddenBatchMint',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721OutOfBoundsIndex',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'InvalidVersion',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'providedVersion',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'currentVersion',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NotApprovedForTransfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'auth',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NotApprovedToMint',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'recipient',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NotAuthorized',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NullToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableInvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
        ],
        created: false,
    },
    '$ref:contracts/FSCOProofs_Mainnet_Proof': {
        deploymentId: '72981537-1ab2-44d7-81ac-cf1046bd74e1',
        address: '0x1FAb9aBE71E379599708b951666ae9E9E9Cc0827',
        name: 'FSCOProofs_Mainnet_Proof',
        chainId: '295',
        abiId: 'e063dda7-dda1-4b59-a86b-93d3c49c6f21',
        abi: [
            {
                type: 'constructor',
                inputs: [
                    {
                        name: 'declarationAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'addAdmin',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'declaration',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'contract Declaration',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getProof',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'tuple',
                        internalType: 'struct Proof.ProofData',
                        components: [
                            {
                                name: 'status',
                                type: 'uint8',
                                internalType: 'enum Proof.ProofStatus',
                            },
                            {
                                name: 'owner',
                                type: 'address',
                                internalType: 'address',
                            },
                            {
                                name: 'tokenId',
                                type: 'uint256',
                                internalType: 'uint256',
                            },
                            {
                                name: 'declarationTokenId',
                                type: 'uint256',
                                internalType: 'uint256',
                            },
                            {
                                name: 'declarationVersion',
                                type: 'uint256',
                                internalType: 'uint256',
                            },
                            {
                                name: 'uri',
                                type: 'string',
                                internalType: 'string',
                            },
                            {
                                name: 'evidence',
                                type: 'string',
                                internalType: 'string',
                            },
                        ],
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getProofStatus',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint8',
                        internalType: 'enum Proof.ProofStatus',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'owner',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'pause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'proofs',
                inputs: [
                    {
                        name: 'proofTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'status',
                        type: 'uint8',
                        internalType: 'enum Proof.ProofStatus',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'declarationVersion',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'evidence',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'removeAdmin',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'renounceOwnership',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'requestProof',
                inputs: [
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'evidence',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'reviewProof',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenByIndex',
                inputs: [
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenOfOwnerByIndex',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'totalSupply',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'transferOwnership',
                inputs: [
                    {
                        name: 'newOwner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'unpause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'BatchMetadataUpdate',
                inputs: [
                    {
                        name: '_fromTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: '_toTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MetadataUpdate',
                inputs: [
                    {
                        name: '_tokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'OwnershipTransferred',
                inputs: [
                    {
                        name: 'previousOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'newOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ProofRequested',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'requester',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ProofReviewed',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'status',
                        type: 'uint8',
                        indexed: false,
                        internalType: 'enum Proof.ProofStatus',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'DeclarationDoesNotExist',
                inputs: [
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'DeclarationVersionDoesNotExist',
                inputs: [
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721EnumerableForbiddenBatchMint',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721OutOfBoundsIndex',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'NotAuthorizedToReview',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'declarationOwner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableInvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ProofDoesNotExist',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ProofNotPending',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'currentStatus',
                        type: 'uint8',
                        internalType: 'enum Proof.ProofStatus',
                    },
                ],
            },
        ],
        created: false,
    },
    '$ref:contracts/FSCOProofs_Mainnet_ProofLink': {
        deploymentId: '17eb7ae5-ada2-401c-9c65-c2a2629b87fb',
        address: '0x5F41bf318082C9fb7b9D737ed3C2C44795f283a3',
        name: 'FSCOProofs_Mainnet_ProofLink',
        chainId: '295',
        abiId: 'e063dda7-dda1-4b59-a86b-93d3c49c6f21',
        abi: [
            {
                type: 'constructor',
                inputs: [
                    {
                        name: '_proofContract',
                        type: 'address',
                        internalType: 'contract IERC721',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'createERC20Link',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'contractAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'userBalance',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'createNFTLink',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'contractAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'targetTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'createTransactionLink',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'transactionHash',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'createWalletLink',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'walletAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'depositFor',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'proofTokenIds',
                        type: 'uint256[]',
                        internalType: 'uint256[]',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'erc20Links',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'contractAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'userBalance',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'linkData',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'status',
                        type: 'uint8',
                        internalType: 'enum ProofLink.LinkStatus',
                    },
                    {
                        name: 'linkType',
                        type: 'uint8',
                        internalType: 'enum ProofLink.LinkType',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'nftLinks',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'contractAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'onERC721Received',
                inputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: '',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'owner',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'pause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'renounceOwnership',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'reviewLink',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transactionLinks',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'transactionHash',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'transferOwnership',
                inputs: [
                    {
                        name: 'newOwner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'underlying',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'contract IERC721',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'unpause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'walletLinks',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'walletAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'withdrawTo',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenIds',
                        type: 'uint256[]',
                        internalType: 'uint256[]',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'LinkDataSet',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'linkType',
                        type: 'uint8',
                        indexed: false,
                        internalType: 'enum ProofLink.LinkType',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'LinkStatusUpdated',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'status',
                        type: 'uint8',
                        indexed: false,
                        internalType: 'enum ProofLink.LinkStatus',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'OwnershipTransferred',
                inputs: [
                    {
                        name: 'previousOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'newOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721UnsupportedToken',
                inputs: [
                    {
                        name: 'token',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'InvalidLinkStatus',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'expected',
                        type: 'uint8',
                        internalType: 'enum ProofLink.LinkStatus',
                    },
                    {
                        name: 'actual',
                        type: 'uint8',
                        internalType: 'enum ProofLink.LinkStatus',
                    },
                ],
            },
            {
                type: 'error',
                name: 'LinkPending',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NotTokenOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableInvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ProofNotApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'status',
                        type: 'uint8',
                        internalType: 'enum Proof.ProofStatus',
                    },
                ],
            },
        ],
        created: false,
    },
    '$ref:contracts/FSCOProofs_Testnet_Declaration': {
        deploymentId: 'd6ce574d-bf3b-4363-a4d9-29add374d74e',
        address: '0x0A18c7C12710078164316cfFf562b25C3c768837',
        name: 'FSCOProofs_Testnet_Declaration',
        chainId: '296',
        abiId: 'e0bdde0f-84c3-4380-84e3-924fad52b051',
        abi: [
            {
                type: 'constructor',
                inputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'approveWallet',
                inputs: [
                    {
                        name: 'approvedAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'burn',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getLatestInfo',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getLatestVersion',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getVersionInfo',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForWallet',
                inputs: [
                    {
                        name: 'wallet',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'mint',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'owner',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'renounceOwnership',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeWallet',
                inputs: [
                    {
                        name: 'revokedAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setTokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: '_tokenURI',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: '_name',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenByIndex',
                inputs: [
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenOfOwnerByIndex',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'totalSupply',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'transferOwnership',
                inputs: [
                    {
                        name: 'newOwner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'versionExists',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'BatchMetadataUpdate',
                inputs: [
                    {
                        name: '_fromTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: '_toTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'DeclarationBurned',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'DeclarationMinted',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'uri',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'DeclarationURIUpdated',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: 'newUri',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                    {
                        name: 'newName',
                        type: 'string',
                        indexed: false,
                        internalType: 'string',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MetadataUpdate',
                inputs: [
                    {
                        name: '_tokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'OwnershipTransferred',
                inputs: [
                    {
                        name: 'previousOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'newOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'WalletApproved',
                inputs: [
                    {
                        name: 'wallet',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approvedAddress',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'WalletRevoked',
                inputs: [
                    {
                        name: 'wallet',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'revokedAddress',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721EnumerableForbiddenBatchMint',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721OutOfBoundsIndex',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'InvalidVersion',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'providedVersion',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'currentVersion',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NotApprovedForTransfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'auth',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NotApprovedToMint',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'recipient',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NotAuthorized',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NullToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableInvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
        ],
        created: false,
    },
    '$ref:contracts/FSCOProofs_Testnet_Proof': {
        deploymentId: '57141bbc-48a6-445c-8eb2-99ba1601aa49',
        address: '0xdae85Ad624b79a78540366940A6F160B27661f65',
        name: 'FSCOProofs_Testnet_Proof',
        chainId: '296',
        abiId: 'e063dda7-dda1-4b59-a86b-93d3c49c6f21',
        abi: [
            {
                type: 'constructor',
                inputs: [
                    {
                        name: 'declarationAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'addAdmin',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'declaration',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'contract Declaration',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getProof',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'tuple',
                        internalType: 'struct Proof.ProofData',
                        components: [
                            {
                                name: 'status',
                                type: 'uint8',
                                internalType: 'enum Proof.ProofStatus',
                            },
                            {
                                name: 'owner',
                                type: 'address',
                                internalType: 'address',
                            },
                            {
                                name: 'tokenId',
                                type: 'uint256',
                                internalType: 'uint256',
                            },
                            {
                                name: 'declarationTokenId',
                                type: 'uint256',
                                internalType: 'uint256',
                            },
                            {
                                name: 'declarationVersion',
                                type: 'uint256',
                                internalType: 'uint256',
                            },
                            {
                                name: 'uri',
                                type: 'string',
                                internalType: 'string',
                            },
                            {
                                name: 'evidence',
                                type: 'string',
                                internalType: 'string',
                            },
                        ],
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getProofStatus',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint8',
                        internalType: 'enum Proof.ProofStatus',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'owner',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'pause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'proofs',
                inputs: [
                    {
                        name: 'proofTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'status',
                        type: 'uint8',
                        internalType: 'enum Proof.ProofStatus',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'declarationVersion',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'evidence',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'removeAdmin',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'renounceOwnership',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'requestProof',
                inputs: [
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'uri',
                        type: 'string',
                        internalType: 'string',
                    },
                    {
                        name: 'evidence',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'reviewProof',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenByIndex',
                inputs: [
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenOfOwnerByIndex',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'totalSupply',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'transferOwnership',
                inputs: [
                    {
                        name: 'newOwner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'unpause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'BatchMetadataUpdate',
                inputs: [
                    {
                        name: '_fromTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                    {
                        name: '_toTokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'MetadataUpdate',
                inputs: [
                    {
                        name: '_tokenId',
                        type: 'uint256',
                        indexed: false,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'OwnershipTransferred',
                inputs: [
                    {
                        name: 'previousOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'newOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ProofRequested',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'requester',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ProofReviewed',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'status',
                        type: 'uint8',
                        indexed: false,
                        internalType: 'enum Proof.ProofStatus',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'DeclarationDoesNotExist',
                inputs: [
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'DeclarationVersionDoesNotExist',
                inputs: [
                    {
                        name: 'declarationTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'version',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721EnumerableForbiddenBatchMint',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721OutOfBoundsIndex',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'index',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'NotAuthorizedToReview',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'declarationOwner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableInvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ProofDoesNotExist',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ProofNotPending',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'currentStatus',
                        type: 'uint8',
                        internalType: 'enum Proof.ProofStatus',
                    },
                ],
            },
        ],
        created: false,
    },
    '$ref:contracts/FSCOProofs_Testnet_ProofLink': {
        deploymentId: '44ff156c-949e-4767-ab19-b3609cd219d9',
        address: '0x0B84b0b638FaCd6171df5f406579355800124088',
        name: 'FSCOProofs_Testnet_ProofLink',
        chainId: '296',
        abiId: 'e063dda7-dda1-4b59-a86b-93d3c49c6f21',
        abi: [
            {
                type: 'constructor',
                inputs: [
                    {
                        name: '_proofContract',
                        type: 'address',
                        internalType: 'contract IERC721',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'DEFAULT_ADMIN_ROLE',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'approve',
                inputs: [
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'balanceOf',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'createERC20Link',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'contractAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'userBalance',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'createNFTLink',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'contractAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'targetTokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'createTransactionLink',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'transactionHash',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'createWalletLink',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'walletAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'depositFor',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'proofTokenIds',
                        type: 'uint256[]',
                        internalType: 'uint256[]',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'erc20Links',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'contractAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'userBalance',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'getRoleAdmin',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'grantRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'hasRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'isApprovedForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'linkData',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'status',
                        type: 'uint8',
                        internalType: 'enum ProofLink.LinkStatus',
                    },
                    {
                        name: 'linkType',
                        type: 'uint8',
                        internalType: 'enum ProofLink.LinkType',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'name',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'nftLinks',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'contractAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'onERC721Received',
                inputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: '',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'owner',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'ownerOf',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'pause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'paused',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'renounceOwnership',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'renounceRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'callerConfirmation',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'reviewLink',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'revokeRole',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'safeTransferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'data',
                        type: 'bytes',
                        internalType: 'bytes',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'setApprovalForAll',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'supportsInterface',
                inputs: [
                    {
                        name: 'interfaceId',
                        type: 'bytes4',
                        internalType: 'bytes4',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'symbol',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'tokenURI',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'string',
                        internalType: 'string',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transactionLinks',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'transactionHash',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'transferFrom',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'transferOwnership',
                inputs: [
                    {
                        name: 'newOwner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'underlying',
                inputs: [],
                outputs: [
                    {
                        name: '',
                        type: 'address',
                        internalType: 'contract IERC721',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'unpause',
                inputs: [],
                outputs: [],
                stateMutability: 'nonpayable',
            },
            {
                type: 'function',
                name: 'walletLinks',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [
                    {
                        name: 'walletAddress',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                stateMutability: 'view',
            },
            {
                type: 'function',
                name: 'withdrawTo',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenIds',
                        type: 'uint256[]',
                        internalType: 'uint256[]',
                    },
                ],
                outputs: [
                    {
                        name: '',
                        type: 'bool',
                        internalType: 'bool',
                    },
                ],
                stateMutability: 'nonpayable',
            },
            {
                type: 'event',
                name: 'Approval',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'ApprovalForAll',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'operator',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'approved',
                        type: 'bool',
                        indexed: false,
                        internalType: 'bool',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'LinkDataSet',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'linkType',
                        type: 'uint8',
                        indexed: false,
                        internalType: 'enum ProofLink.LinkType',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'LinkStatusUpdated',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                    {
                        name: 'status',
                        type: 'uint8',
                        indexed: false,
                        internalType: 'enum ProofLink.LinkStatus',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'OwnershipTransferred',
                inputs: [
                    {
                        name: 'previousOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'newOwner',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Paused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleAdminChanged',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'previousAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'newAdminRole',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleGranted',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'RoleRevoked',
                inputs: [
                    {
                        name: 'role',
                        type: 'bytes32',
                        indexed: true,
                        internalType: 'bytes32',
                    },
                    {
                        name: 'account',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'sender',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Transfer',
                inputs: [
                    {
                        name: 'from',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'to',
                        type: 'address',
                        indexed: true,
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        indexed: true,
                        internalType: 'uint256',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'event',
                name: 'Unpaused',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        indexed: false,
                        internalType: 'address',
                    },
                ],
                anonymous: false,
            },
            {
                type: 'error',
                name: 'AccessControlBadConfirmation',
                inputs: [],
            },
            {
                type: 'error',
                name: 'AccessControlUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'neededRole',
                        type: 'bytes32',
                        internalType: 'bytes32',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721IncorrectOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InsufficientApproval',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidApprover',
                inputs: [
                    {
                        name: 'approver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOperator',
                inputs: [
                    {
                        name: 'operator',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidReceiver',
                inputs: [
                    {
                        name: 'receiver',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721InvalidSender',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721NonexistentToken',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ERC721UnsupportedToken',
                inputs: [
                    {
                        name: 'token',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'EnforcedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'ExpectedPause',
                inputs: [],
            },
            {
                type: 'error',
                name: 'InvalidLinkStatus',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'expected',
                        type: 'uint8',
                        internalType: 'enum ProofLink.LinkStatus',
                    },
                    {
                        name: 'actual',
                        type: 'uint8',
                        internalType: 'enum ProofLink.LinkStatus',
                    },
                ],
            },
            {
                type: 'error',
                name: 'LinkPending',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
            {
                type: 'error',
                name: 'NotTokenOwner',
                inputs: [
                    {
                        name: 'sender',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableInvalidOwner',
                inputs: [
                    {
                        name: 'owner',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'OwnableUnauthorizedAccount',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
            },
            {
                type: 'error',
                name: 'ProofNotApproved',
                inputs: [
                    {
                        name: 'tokenId',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                    {
                        name: 'status',
                        type: 'uint8',
                        internalType: 'enum Proof.ProofStatus',
                    },
                ],
            },
        ],
        created: false,
    },
} as const;
