import { GetContracts, GetDeployments, GetWallets } from '@fsco/shared';
import { did } from './did.staging';

export const environment = {
    did,
    appName: 'fsco-proofs',
    realm: 'fsco-platform',
    clientId: 'fsco-proofs',
    authenticationUrl: 'https://id.staging.fsco.io',
    stage: 'staging',
    production: false,
    apiUrl: 'https://staging.api.fsco.io/v2',
    apiDelay: 1000,
    useLocalStorage: true,
    useMockData: false,
    enableLogin: true,
    online: true,
    chainId: 295,
    shareringConfig: {
        qrBaseUrl: 'https://shareringme.page.link/',
        qrSubfix: '&apn=network.sharering.me&isi=6476899324&ibi=network.sharering.shareringme',
        vqlBaseUrl: 'https://sharering.network/vql',
        graphqlBaseUrl: 'https://api.shareri.ng/graphql',
        clientId: '67578cf278d738a17dce4f25',
        queryId: '67578f5378d738a17dce4fd0',
        queryOwner: 'shareledger1p4nxs2pqga7sm0nsc5739w8600t9zhzyy592gk',
    },
    chainConfig: {
        wallets: {
            adminWallet:
                'hedera_mainnet_deployer' as const satisfies GetWallets<
                    typeof did
                >,
            user1Wallet:
                'hedera_mainnet_deployer' as const satisfies GetWallets<
                    typeof did
                >,
            user2Wallet:
                'hedera_mainnet_deployer' as const satisfies GetWallets<
                    typeof did
                >,
        },
        deployments: {
            dvctToken:
                'ShareRing_Mainnet_DocumentVerifiableCredentialsToken' as const satisfies GetDeployments<
                    typeof did
                >,
            vctToken:
                'ShareRing_Mainnet_VerifiableCredentialsToken' as const satisfies GetDeployments<
                    typeof did
                >,
        },
        contracts: {
            declarations:
                'FSCOProofs_Mainnet_Declaration' as const satisfies GetContracts<
                    typeof did
                >,
            proofs: 'FSCOProofs_Mainnet_Proof' as const satisfies GetContracts<
                typeof did
            >,
            links: 'FSCOProofs_Mainnet_ProofLink' as const satisfies GetContracts<
                typeof did
            >,
        },
    },
    defaultHeaders: {
        // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJ1c2VySWQiOiIwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwIiwidGVuYW50SWQiOiIwMGI0YjhlNi02ZTljLTRlYzgtOGJjMC00ZjI3Y2JhNTdlMjYiLCJhcHBzIjpbXSwiaXNTdXBwZXJLZXkiOnRydWUsIm9yZ2FuaXNhdGlvbklkIjoiMDBiNGI4ZTYtNmU5Yy00ZWM4LThiYzAtNGYyN2NiYTU3ZTI2IiwiYXR0cmlidXRlcyI6W10sImlzS2V5Ijp0cnVlLCJpYXQiOjE3MzI4NTM3MzN9.13W4eqIe7vbx_O8KVqg5MG-UYs8v0z-c6i94kF0HYwQ`,
    },
};
